<template>
  <div>
    <Header></Header>
    <div class="info">
      <div class="info-box">
        <info1 v-if="id==1"></info1>
        <info2 v-if="id==2"></info2>
        <info3 v-if="id==3"></info3>
        <info4 v-if="id==4"></info4>
        <info5 v-if="id==5"></info5>
        <info6 v-if="id==6"></info6>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>


<script>
import Header from "@/components/header.vue"
import Footer from "@/components/footer.vue"
import info1 from "@/components/info1.vue";
import info2 from "@/components/info2.vue";
import info3 from "@/components/info3.vue";
import info4 from "@/components/info4.vue";
import info5 from "@/components/info5.vue";
import info6 from "@/components/info6.vue";
export default {
  components: {
    Header,
    Footer,
    info1,
    info2,
    info3,
    info4,
    info5,
    info6,
  },
  data() {
    return {
      id: 1
    }
  },
  mounted() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id
    }
  }

}
</script>


<style lang="scss">
.info {
  background-color: #f5f6f8;
  overflow: hidden;
  .info-box {
    width: 702px;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    margin: 32px auto 80px;
    overflow: hidden;
    padding: 0 24px;
    img {
      width: 100%;
    }
  }
}
.m3nContent {
  padding: 10px 0px;
  font-size: 28px;
  font-weight: 400;
  color: #181818;
  line-height: 44px;
}
.title {
  font-size: 30px;
  font-weight: 500;
  color: #181818;
  text-align: center;
  line-height: 44px;
  margin: 20px auto;
  width: 654px;
}
.date {
  font-size: 26px;
  font-weight: 400;
  color: #7b7b7b;
  text-align: center;
}
</style>