<template>
  <div>
    <div class="title">一文教你医院互联互通标准化成熟度测评中对医院信息系统的要求</div>
    <div class="date">2022-12-02</div>
    <div class="line"></div>
    <div class="m3nLtop">
      <div class="m3nContent">
        <p>近日，国家卫健委统计信息中心发布了《2021年度国家医疗健康信息互联互通标准化成熟度测评结果(第一批)公示》，将参加第一批测评的16个区域和178家医院测评结果进行公示。<span style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; font-size: 16px; letter-spacing: 1.5px; color: #000000; font-family: Helvetica, Arial, sans-serif; visibility: visible;"><span style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; color: #888888; font-family: 微软雅黑; font-size: 14px; letter-spacing: 0.544px; background-color: #F7F7F8; visibility: visible;"></span></span></p>
        <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; clear: both; min-height: 1em; color: rgb(34, 34, 34); font-family: system-ui, -apple-system, BlinkMacSystemFont, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif; font-size: 17px; letter-spacing: 0.544px; text-align: justify; white-space: normal; background-color: rgb(255, 255, 255); line-height: 1.75em; visibility: visible; box-sizing: border-box !important; overflow-wrap: break-word !important;"><span style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; font-size: 16px; letter-spacing: 1.5px; color: #000000; font-family: Helvetica, Arial, sans-serif; visibility: visible;"><br></span></p>
        <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; clear: both; min-height: 1em; color: rgb(34, 34, 34); font-family: system-ui, -apple-system, BlinkMacSystemFont, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif; font-size: 17px; letter-spacing: 0.544px; text-align: center; white-space: normal; background-color: rgb(255, 255, 255); line-height: 1.75em; visibility: visible; box-sizing: border-box !important; overflow-wrap: break-word !important;">
          <img src="https://static.drlianzhuren.com/HairTransplant/CloudNumber/news/3/1.png">
        </p>
        <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; clear: both; min-height: 1em; color: rgb(34, 34, 34); font-family: system-ui, -apple-system, BlinkMacSystemFont, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif; font-size: 17px; letter-spacing: 0.544px; text-align: justify; white-space: normal; background-color: rgb(255, 255, 255); line-height: 1.75em; visibility: visible; box-sizing: border-box !important; overflow-wrap: break-word !important;"><br></p>
        <p>国家医疗健康信息互联互通标准化成熟度测评（以下简称医院互联互通测评）的目的，是为了以测促用、以测促改、以测促建，有效促进医疗机构打破信息孤岛，实现互联互通和信息共享。互联互通测评等级越高，越能证明医院整体信息化建设和应用水平更好，患者越能享受到更高质量的就诊服务，这是证明医院综合实力的重要指标之一。</p>
        <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; clear: both; min-height: 1em; color: rgb(34, 34, 34); font-family: system-ui, -apple-system, BlinkMacSystemFont, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif; font-size: 17px; letter-spacing: 0.544px; text-align: justify; white-space: normal; background-color: rgb(255, 255, 255); visibility: visible; box-sizing: border-box !important; overflow-wrap: break-word !important;"><br></p>
        <section data-role="title" data-tools="135编辑器" data-id="119836" style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; color: rgb(34, 34, 34); font-family: system-ui, -apple-system, BlinkMacSystemFont, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif; font-size: 17px; letter-spacing: 0.544px; text-align: justify; white-space: normal; background-color: rgb(255, 255, 255); visibility: visible; box-sizing: border-box !important; overflow-wrap: break-word !important;">
          <section hm_fix="368:261" style="margin: 10px auto; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; display: flex; justify-content: center; visibility: visible;">
            <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; visibility: visible;">
              <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; display: flex; transform-style: preserve-3d; visibility: visible;">
                <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; flex-shrink: 0; display: flex; align-items: flex-start; transform: translateZ(10px) translate(1.7px, 1.7px); visibility: visible;">
                  <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; width: 0px; height: 1px; border-top: 10px solid rgb(255, 255, 255); border-right: 10px solid transparent; overflow: hidden; visibility: visible;"><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; visibility: visible;"></section>
                  <section style="margin: 0px 0px 0px -10px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; width: 0px; height: 1px; border-bottom: 10px solid rgb(28, 130, 222); border-left: 10px solid transparent; overflow: hidden; visibility: visible;"><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; visibility: visible;"></section>
                </section>
                <section style="margin: 0px 0px 0px -10px; padding: 5px 0px 0px 5px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; transform: translateZ(5px); visibility: visible;">
                  <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; background-color: rgb(220, 237, 250); visibility: visible;">
                    <section style="margin: 0px; padding: 9px 25px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; background-color: rgb(255, 255, 255); border-width: 1px; border-style: solid; border-color: rgb(28, 130, 222); font-size: 16px; color: rgb(28, 130, 222); transform: translate(-3px, -3px); visibility: visible;"><span style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; font-family: Helvetica, Arial, sans-serif; visibility: visible;"><strong data-brushtype="text" style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; visibility: visible;">医院互联互通测评评审内容</strong></span></section>
                  </section>
                </section>
              </section>
            </section>
          </section>
        </section>

        <p>医院互联互通测评分为五级七等，一级和二级主要要求数据集的标准化，三级更偏重对共享文档和数据整合的考察，四乙四甲说明医院已经建成业务协同、信息共享的信息平台，最后的五乙五甲证明医院可以展现出互联互通的实际应用效果。</p>
        <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; clear: both; min-height: 1em; color: rgb(34, 34, 34); font-family: system-ui, -apple-system, BlinkMacSystemFont, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif; font-size: 17px; letter-spacing: 0.544px; text-align: justify; white-space: normal; background-color: rgb(255, 255, 255); box-sizing: border-box !important; overflow-wrap: break-word !important;"><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important;"></p>
        <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; clear: both; min-height: 1em; color: rgb(34, 34, 34); font-family: system-ui, -apple-system, BlinkMacSystemFont, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif; font-size: 17px; letter-spacing: 0.544px; white-space: normal; background-color: rgb(255, 255, 255); text-align: center; box-sizing: border-box !important; overflow-wrap: break-word !important;">
          <img src="https://static.drlianzhuren.com/HairTransplant/CloudNumber/news/3/2.jpg">
        </p>
        <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; clear: both; min-height: 1em; color: rgb(34, 34, 34); font-family: system-ui, -apple-system, BlinkMacSystemFont, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif; font-size: 17px; letter-spacing: 0.544px; text-align: justify; white-space: normal; background-color: rgb(255, 255, 255); line-height: 1.75em; box-sizing: border-box !important; overflow-wrap: break-word !important;"><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important;"></p>
        <p>根据具体测评指标，我们不难看出，医院互联互通测评主要分为四个维度：</p>
        <p>维度1：数据标准化建设（数据集和共享文档标准化）；</p>
        <p>维度2：互联互通标准化建设（互联互通服务、平台运行性能和技术架构）；</p>
        <p>维度3：基础设施建设（硬件设施、网络和信息安全、业务应用系统建设情况）；</p>
        <p>维度4：互联互通应用效果（应用建设情况及利用情况及平台联通业务范围）。</p>
        <p><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important;"></p>
        <p>当然，每年测评的内容都会有细节上的更新和补充，伴随着测评的细化和参评机构的增多，互联互通测评的难度也在逐年上升。那么想要通过互联互通评审或者提高测评等级的医疗机构，究竟该如何做呢？</p>
        <p><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important;"></p>
        <p>今天，我们从以下几个要点给大家一些参考。</p>
        <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; clear: both; min-height: 1em; color: rgb(34, 34, 34); font-family: system-ui, -apple-system, BlinkMacSystemFont, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif; font-size: 17px; letter-spacing: 0.544px; text-align: justify; white-space: normal; background-color: rgb(255, 255, 255); box-sizing: border-box !important; overflow-wrap: break-word !important;"><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important;"></p>

        <section data-role="title" data-tools="135编辑器" data-id="119836" style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; color: rgb(34, 34, 34); font-family: system-ui, -apple-system, BlinkMacSystemFont, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif; font-size: 17px; letter-spacing: 0.544px; text-align: justify; white-space: normal; background-color: rgb(255, 255, 255); box-sizing: border-box !important; overflow-wrap: break-word !important;">
          <section hm_fix="368:261" style="margin: 10px auto; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; display: flex; justify-content: center;">
            <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important;">
              <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; display: flex; transform-style: preserve-3d;">
                <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; flex-shrink: 0; display: flex; align-items: flex-start; transform: translateZ(10px) translate(1.7px, 1.7px);">
                  <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; width: 0px; height: 1px; border-top: 10px solid rgb(255, 255, 255); border-right: 10px solid transparent; overflow: hidden;"><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important;"></section>
                  <section style="margin: 0px 0px 0px -10px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; width: 0px; height: 1px; border-bottom: 10px solid rgb(28, 130, 222); border-left: 10px solid transparent; overflow: hidden;"><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important;"></section>
                </section>
                <section style="margin: 0px 0px 0px -10px; padding: 5px 0px 0px 5px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; transform: translateZ(5px);">
                  <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; background-color: rgb(220, 237, 250);">
                    <section style="margin: 0px; padding: 9px 25px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; background-color: rgb(255, 255, 255); border-width: 1px; border-style: solid; border-color: rgb(28, 130, 222); font-size: 16px; color: rgb(28, 130, 222); transform: translate(-3px, -3px);"><span style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; font-family: Helvetica, Arial, sans-serif;"><strong data-brushtype="text" style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important;">医院互联互通过审要点</strong></span></section>
                  </section>
                </section>
              </section>
            </section>
          </section>
        </section>
        <section data-role="paragraph" style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; color: rgb(34, 34, 34); font-family: system-ui, -apple-system, BlinkMacSystemFont, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif; font-size: 17px; letter-spacing: 0.544px; text-align: justify; white-space: normal; background-color: rgb(255, 255, 255); box-sizing: border-box !important; overflow-wrap: break-word !important;">
          <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; clear: both; min-height: 1em; box-sizing: border-box !important; overflow-wrap: break-word !important;"><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important;"></p>
        </section>
        <section data-tools="135编辑器" data-id="119004" style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; color: rgb(34, 34, 34); font-family: system-ui, -apple-system, BlinkMacSystemFont, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif; font-size: 17px; letter-spacing: 0.544px; text-align: justify; white-space: normal; background-color: rgb(255, 255, 255); box-sizing: border-box !important; overflow-wrap: break-word !important;">
          <section style="margin: 10px auto; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important;">
            <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important;">
              <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; display: flex; justify-content: flex-start;">
                <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; display: flex;">
                  <section style="margin: 0px; padding: 0px 10px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; display: flex; background-color: rgb(127, 172, 227);">
                    <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; font-size: 16px; letter-spacing: 1.5px; color: rgb(255, 255, 255); height: 30px; line-height: 30px;"><span style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; font-family: Helvetica, Arial, sans-serif;"><strong style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important;">要点1：电子病历建设</strong></span><strong data-brushtype="text" style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important;"></strong></section>
                  </section>
                  <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; width: 0px; height: 1px; border-bottom: 30px solid rgb(127, 172, 227); border-right: 15px solid transparent; overflow: hidden;"><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important;"></section>
                </section>
                <section style="margin: 0px 0px 0px 4px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; display: flex; align-items: flex-end;">
                  <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; width: 7px; height: 7px; background-color: rgb(176, 245, 242); overflow: hidden; transform: skew(25deg);"><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important;"></section>
                </section>
              </section>
              <section style="margin: 0px; padding: 15px 0px 15px 10px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; border-left: 1px solid rgb(127, 172, 227);">
                <section data-autoskip="1" style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; line-height: 1.75em; letter-spacing: 1.5px; font-size: 14px; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">
                  <p>互联互通测评指标中“2.1数据集标准化情况”和“2.2共享文档标准化情况”，其实都是对电子病历标准化的考量，这是互联互通测评的基础项，也是重头戏。</p>
                  <p><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important;"></p>
                  <p>其中2.1主要参照的是《电子病历基本数据集》，包括17个电子病历基本数据集和58个子集；2.2主要参照的是《电子病历共享文档规范》和《电子病历共享文档规范》。</p>
                  <p><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important;"></p>
                  <p>从评分标准上看，只有电子病历的数据集合共享文档符合国家标准，才能为互联互通测评打好地基。</p>
                  <p><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important;"></p>
                  <p>云数智康HIS智慧医疗解决方案提供符合国家标准的全结构化电子病历系统，通过建立全院病历模板、科室病历模板、专科病历模板、语句模板、统一格式、书写要求及查阅书写概要，打造优质电子病历系统。同时，以电子病历为核心的医生工作站系统，还支持所见即所得的录入模式、矢量图绘制及三级医师审核和修改痕迹管理，并凭借完善的环节质控、末端质控、自动质控等方式协助临床医生提高病历质量。</p>
                  <p><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important;"></p>
                </section>
              </section>
            </section>
            <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important;">
              <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; display: flex; justify-content: flex-start;">
                <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; display: flex;">
                  <section style="margin: 0px; padding: 0px 10px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; display: flex; background-color: rgb(127, 172, 227);">
                    <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; font-size: 16px; letter-spacing: 1.5px; color: rgb(255, 255, 255); height: 30px; line-height: 30px;"><strong style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important;"><span style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; font-family: Helvetica, Arial, sans-serif;">要点2：集成平台建设</span></strong></section>
                  </section>
                  <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; width: 0px; height: 1px; border-bottom: 30px solid rgb(127, 172, 227); border-right: 15px solid transparent; overflow: hidden;"><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important;"></section>
                </section>
                <section style="margin: 0px 0px 0px 4px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; display: flex; align-items: flex-end;">
                  <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; width: 7px; height: 7px; background-color: rgb(176, 245, 242); overflow: hidden; transform: skew(25deg);"><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important;"></section>
                </section>
              </section>
              <section style="margin: 0px; padding: 15px 0px 15px 10px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; border-left: 1px solid rgb(127, 172, 227);">
                <section data-autoskip="1" style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; line-height: 1.75em; letter-spacing: 1.5px; font-size: 14px; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">
                  <p>在医院互联互通测评中，100分中集成平台相关分数就高达65分，其中包括30分的数据资源标准化、10分的平台技术架构以及25分的互联互通交互服务。</p>
                  <p><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important;"></p>
                  <p>为助力医疗机构通过高水平的互联互通评级，云数智康IIP集成平台以标准化数据和接口为基础，完成医院内部各类异构信息系统的集成和交互，并通过平台门户层、平台应用层、平台服务层、平台资源层、平台交换层、业务应用层六大部分，实现系统统一管理、多系统接入管理及跨平台数据共享；同时，还能通过构建临床数据中心和运营数据中心，为精准医疗提供预警支持、辅助决策支持。</p>
                  <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; clear: both; min-height: 1em; box-sizing: border-box !important; overflow-wrap: break-word !important;"><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important;"></p>
                </section>
              </section>
            </section>
            <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important;">
              <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; display: flex; justify-content: flex-start;">
                <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; display: flex;">
                  <section style="margin: 0px; padding: 0px 10px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; display: flex; background-color: rgb(127, 172, 227);">
                    <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; font-size: 16px; letter-spacing: 1.5px; color: rgb(255, 255, 255); height: 30px; line-height: 30px;"><strong style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important;"><span style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; font-family: Helvetica, Arial, sans-serif;">要点3：运行管理系统建设</span></strong></section>
                  </section>
                  <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; width: 0px; height: 1px; border-bottom: 30px solid rgb(127, 172, 227); border-right: 15px solid transparent; overflow: hidden;"><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important;"></section>
                </section>
                <section style="margin: 0px 0px 0px 4px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; display: flex; align-items: flex-end;">
                  <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; width: 7px; height: 7px; background-color: rgb(176, 245, 242); overflow: hidden; transform: skew(25deg);"><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important;"></section>
                </section>
              </section>
              <section style="margin: 0px; padding: 15px 0px 15px 10px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; border-left: 1px solid rgb(127, 172, 227);">
                <section data-autoskip="1" style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; line-height: 1.75em; letter-spacing: 1.5px; font-size: 14px; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">
                  <p>医院互联互通测评方案中，还明确提出要关注运营管理系统的建设情况，通过三级、四级乙等、四级甲等、五级乙等、五级甲等测评的医院分别需要满足3个、6个、8个、12个、14个的数量需求。</p>
                  <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; max-width: 100%; clear: both; min-height: 1em; max-inline-size: 100%; cursor: text; color: rgb(0, 0, 0); font-size: 17px; caret-color: rgb(255, 0, 0); line-height: 1.75em; font-family: 微软雅黑, sans-serif; outline: none 0px !important; box-sizing: border-box !important; overflow-wrap: break-word !important;"><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important;"></p>
                  <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; max-width: 100%; clear: both; min-height: 1em; max-inline-size: 100%; cursor: text; color: rgb(0, 0, 0); font-size: 17px; caret-color: rgb(255, 0, 0); line-height: 1.75em; font-family: 微软雅黑, sans-serif; outline: none 0px !important; box-sizing: border-box !important; overflow-wrap: break-word !important;"><span style="margin: 0px; padding: 0px; outline: none 0px !important; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; max-inline-size: 100%; cursor: text; font-size: 16px; font-family: Helvetica, Arial, sans-serif;">
                      <img src="https://static.drlianzhuren.com/HairTransplant/CloudNumber/news/3/3.png"></span></p>
                  <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; max-width: 100%; clear: both; min-height: 1em; max-inline-size: 100%; cursor: text; color: rgb(0, 0, 0); font-size: 17px; caret-color: rgb(255, 0, 0); font-family: 微软雅黑, sans-serif; outline: none 0px !important; box-sizing: border-box !important; overflow-wrap: break-word !important;"><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important;"></p>
                  <p>云数智康HRP智慧管理解决方案提供人力资源管理、财务管理、固定资产管理、预算管理、绩效管理、供应链管理、库存管理等多个运营管理模块，一体化提高医疗机构管理效率，助力医院提高互联互通测评等级。</p>
                </section>
              </section>
            </section>
          </section>
        </section>
        <section data-role="paragraph" style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; color: rgb(34, 34, 34); font-family: system-ui, -apple-system, BlinkMacSystemFont, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif; font-size: 17px; letter-spacing: 0.544px; text-align: justify; white-space: normal; background-color: rgb(255, 255, 255); box-sizing: border-box !important; overflow-wrap: break-word !important;">
          <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; clear: both; min-height: 1em; box-sizing: border-box !important; overflow-wrap: break-word !important;"><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important;"></p>
        </section>
        <section data-role="title" data-tools="135编辑器" data-id="119836" style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; color: rgb(34, 34, 34); font-family: system-ui, -apple-system, BlinkMacSystemFont, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif; font-size: 17px; letter-spacing: 0.544px; text-align: justify; white-space: normal; background-color: rgb(255, 255, 255); box-sizing: border-box !important; overflow-wrap: break-word !important;">
          <section hm_fix="368:261" style="margin: 10px auto; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; display: flex; justify-content: center;">
            <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important;">
              <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; display: flex; transform-style: preserve-3d;">
                <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; flex-shrink: 0; display: flex; align-items: flex-start; transform: translateZ(10px) translate(1.7px, 1.7px);">
                  <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; width: 0px; height: 1px; border-top: 10px solid rgb(255, 255, 255); border-right: 10px solid transparent; overflow: hidden;"><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important;"></section>
                  <section style="margin: 0px 0px 0px -10px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; width: 0px; height: 1px; border-bottom: 10px solid rgb(28, 130, 222); border-left: 10px solid transparent; overflow: hidden;"><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important;"></section>
                </section>
                <section style="margin: 0px 0px 0px -10px; padding: 5px 0px 0px 5px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; transform: translateZ(5px);">
                  <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; background-color: rgb(220, 237, 250);">
                    <section style="margin: 0px; padding: 9px 25px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; background-color: rgb(255, 255, 255); border-width: 1px; border-style: solid; border-color: rgb(28, 130, 222); font-size: 16px; color: rgb(28, 130, 222); transform: translate(-3px, -3px);"><span style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; font-family: Helvetica, Arial, sans-serif;"><strong data-brushtype="text" style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important;">小结</strong></span></section>
                  </section>
                </section>
              </section>
            </section>
          </section>
        </section>

        <p>医院信息互联互通是医院信息化建设发展重点，想要提升互联互通标准化成熟度，在建设过程中必须要按照国家标准，并结合医院具体需求，对信息化进行整体设计和布局。云数智康凭借十七年的医疗行业服务经验，也愿以助力上千家医疗机构信息化建设的成功经验，为更多医院互联互通评级贡献力量。</p>

      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
</style>