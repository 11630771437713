<template>
  <div>
    <div class="title">医院管理系统丨ODR助力PDCA循环，共同改善医院运营管理</div>
    <div class="date">2023-02-09</div>
    <div class="line"></div>
    <div class="m3nLtop">
      <div class="m3nContent">
        <section data-role="outer" class="article135" label="edit by 135editor">
          <p>近年来，在国家卫健委领导下，医院评审工作稳步推进，逐渐从“以结果为导向”向“以问题为导向”转变。也就是说，在评审过程中更注重发现医院管理中的不足，让医院管理系统能用融合管理工具去解决这些问题点，从而实现医院运营管理质量的不断提升。<br></p>
          <section data-role="outer" label="edit by 135editor">
      
            <p>那医院该选择什么管理工具去发现并高质量解决问题呢？《医疗质量管理办法》第四十七条提到可运用质量环（PDCA循环）管理工具实现医疗质量管理目标和持续改进；在2011版三级医院评审标准中，也运用质量管理PDCA的原理来进行评审；包括高权威性的JCI和ISO认证，PDCA的理念也早就贯穿其中。</p>
      
      
            <p>PDCA到底是什么?在医院管理中能起到什么作用？ODR如何帮助PDCA循环运行？今天，我们一起来了解一下吧~</p>
      
            <section data-role="title" data-tools="135编辑器" data-id="117212">
              <section>
                <section>
                  <section>
                    <section>
                      <section>
                        <section>
                          <section style="text-align: center;"><span style="color: #4F81BD;"><strong>PDCA循环是什么</strong></span></section>
                        </section>
                      </section>
                    </section>
                  </section>
                </section>
              </section>
            </section>
      
            <p>PDCA是由美国著名的质量管理专家E.戴明博士提出的，所以又称戴明环，是一个持续改进模型，它包括持续改进与不断学习的四个循环反复的步骤，即计划（Plan）、执行（Do）、检查（Check）、处理（Act）。前一个循环是后一个循环的依据，后一个循环是前一个循环的落实。四个循环每转动一周就会上升一个台阶，相应就会解决一些问题，四个小循环不断运转，医院的管理水平就会随之节节提高。</p>
      
            <p>其具体流程分为四个循环和八大步骤，分别是：</p>
      
            <p>【Plan计划阶段】<br></p>
            <section data-tools="135编辑器" data-id="119822">
              <section>
                <section>
                  <section data-autoskip="1">
                    <p>步骤1：分析现状，找出问题</p>
                    <p>步骤2：分析各种影响因素或原因</p>
                    <p>步骤3：找出主要影响因素</p>
                    <p>步骤4：针对主要原因，制定措施计划，提出行动计划</p>
              
                    <p>【Do执行阶段】</p>
                    <p>步骤5：实施行动计划</p>
              
                    <p>【Check检查阶段】</p>
                    <p>步骤6：评估结果</p>
              
                    <p>【Act处理阶段】</p>
                    <p>步骤7：总结和标准化</p>
                    <p>步骤8：把未解决或新出现的问题转入下一个PDCA循环</p>
                  </section>
                </section>
              </section>
            </section>
            <section data-role="title" data-tools="135编辑器" data-id="117212">
              <section>
                <section>
                  <section>
                    <section>
                      <section>
                        <section>
                          <section>
                            <section><br></section>
                          </section>
                        </section>
                      </section>
                    </section>
                    <section>
                      <section>
                        <section>
                          <section style="text-align: center;"><strong><span style="color: #4F81BD;">PDCA循环在医院中的实践</span></strong></section>
                        </section>
                      </section>
                    </section>
                  </section>
                </section>
              </section>
            </section>
      
            <p>实践1：提高（特定）单病种指标总执行率</p>
            <p>某医院单病种上报例数持续增长，但指标总执行率都比较差。为了提高总执行率，医院成立CQI小组，设定执行率要达到国家平均值。在PDCA循环过程中，小组确认问题主要出现在制度不完善、缺乏监管、无医院管理系统上报系统等方面，并针对这些问题逐一进行解决。PDCA循环完成后，该院上报单病种质控病历3181例，位居湖北省第一。</p>
      
            <p>实践2：提高检验危急值报告率</p>
            <p>某医院检验危急值管理出现报告不及时、漏报等问题。为了改善这一情况，医院成立CQI小组，设定100%的改善目标和行动计划。在3个月的PDCA循环中，小组分析了相关原因，通过二八法则确认报告系统不完善和监管不到位是主要原因。在行动阶段，小组运用5W1H法制定改善策略，安装检验危急值报告系统，修订相关制度流程并安排医务科定期督导检查。3个月后，检验危急值报告率成功提高到100%。</p>
      
            <p>实践3：改进医疗信息数据库管理</p>
            <p>医院在日常工作检查中发现，医院医疗信息数据库不健全的问题。为了改进数据库管理，医院成立CQI小组，通过鱼骨图分析原因，并最终确认问题出在质控体系不健全、诊断编码错误、信息系统滞后等9个方面。并针对这些问题，开展了为期26周的行动改进。26周后，医院医疗信息数据库项目完成率由19%提升至100%。</p>
      
            <p>工欲善其事，必先利其器。从上述案例中，我们不难发现，很多医院的运营问题都出现在“数据”上，比如无xx信息系统（缺数据）、信息系统滞后（数据质量差）、医疗信息不健全（数据库不完善）等等。云数智康医院医院管理系统运营数据中心（ODR）建设方案，能够与国家指标评价和医院先进管理模式紧密贴合。在数据集成与数据治理的基础上，将各系统不能共享的孤岛信息，转换成符合国家标准的数据集，并凭借丰富的医疗行业研发和服务经验，沉淀出成熟的运营管理模型内置于ODR产品中，赋能医疗集团和医院的科学决策。</p>
      
      
            <p>更重要的是， 云数智康医院管理系统医院运营数据中心（ODR）能够深度支撑PDCA循环工作，其准确丰富的数据指标、实时全面的数据展示可以起到关键性作用。它能够结合医院业务增长计划，确定各项指标的目标值；在具体执行阶段，可以获取医院各科室的实际运营数据并建立数据仓库；在分析检查阶段，ODR支持依据实际运营数据，与目标值进行自动对比，并追溯差异原因。</p>
      
            <section data-role="title" data-tools="135编辑器" data-id="117212">
              <section>
                <section>
                  <section>
                    <section>
                      <section>
                        <section>
                          <section style="text-align: center;"><span style="color: #4F81BD;"><strong>小结</strong></span></section>
                        </section>
                      </section>
                    </section>
                  </section>
                </section>
              </section>
            </section>
      
            <p>PDCA循环管理工具是改善医院质量必不可少的方法之一，云数智康医院管理系统医院运营数据中心（ODR）愿为大家在实施PDCA的过程中，提供精细化的数据支撑，助力管理决策的制定和落地，让医院质量持续改进，提升医院医疗品质和服务成效。</p>
          </section>
        </section>
  
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
</style>