<template>
  <div>
    <div class="title">智慧医院建设路上，必须关注的7大信息化评价标准</div>
    <div class="date">2021-12-28</div>
    <div class="line"></div>
    <div class="m3nLtop">
      <div class="m3nContent">
        <p>智慧医院——一个近些年各大医院都在不断追求的目标。它并不是医院的新物种，而是在一般医院的基础上，利用各种新技术的应用支持，让医院医疗质量更可控、服务水平更优质、管理运营更高效。</p>
        <p>&nbsp;</p>
        <p>但到底如何建设智慧医院，或者说医院信息化建设达到什么程度才能被称为智慧医院，很多医院都是在自己摸索，边走边看，缺乏体系。今天，云数智康为大家整理了近几年国家卫健委发布的关于医院信息化的评价标准，希望大家能够从相关标准中，探索医院信息化智慧化建设的重要性以及从哪些方面开展行动。</p>
        <p>&nbsp;</p>
        <p><span style="color: #4F81BD;">1．公立医院高质量发展</span></p>
        <p>《国务院办公厅关于推动公立医院高质量发展的意见》要求强化信息化支撑作用。推进电子病历、智慧服务、智慧管理“三位一体”的智慧医院建设和医院信息标准化建设。《关于印发公立医院高质量发展促进行动（2021-2025年）的通知》中要求将信息化作为医院基本建设的优先领域，到 2022 年，全国三级公立医院电子病历应用水平平均级别达到 4 级。</p>
        <p>&nbsp;</p>
        <p><span style="color: #4F81BD;">2．公立医院绩效考核</span></p>
        <p>在《国家三级公立医院绩效考核操作手册（2022版）》中规定：将医院的电子病历应用水平纳入到了三级公立医院绩效考核当中，从系统功能实现、有效应用范围、数据质量三个维度对医院的电子病历和临床应用系统进行评价。</p>
        <p>&nbsp;</p>
        <p><span style="color: #4F81BD;">3．三级医院等级评审</span></p>
        <p>为持续做好医院评审工作，保障医院评审标准与现行管理政策的一致性，国家卫生健康委员会组织制定了《三级医院评审标准（2022年版）》及其实施细则。</p>
        <p>其中有关信息化建设的内容如下：</p>
        <p>（一百五十六）建立以院长为核心的医院信息化建设领导小组，有负责信息管理的专职机构，建立各部门间的组织协调机制，制订信息化发展规划，有与信息化建设配套的相关管理制度。</p>
        <p>（一百五十七）医院信息系统能够系统、连续、准确地采集、存储、传输、处理相关的信息，为医院管理、临床医疗和服务提供包括决策支持类的信息技术支撑，并根据国家相关规定，实现信息互联互通、交互共享。</p>
        <p>（一百五十八）落实《中华人民共和国网络安全法》，实施国家信息安全等级保护制度，实行信息系统按等级保护分级管理，保障网络信息安全，保护患者隐私。推动系统运行维护的规范化管理，落实突发事件响应机制，保证业务的连续性。</p>
        <p>（一百五十九）根据《中华人民共和国统计法》与卫生健康行政部门规定，完成医院基本运行状况、医疗质量安全、医疗技术、诊疗信息和临床用药监测信息等相关数据报送工作，确保数据真实可靠、可追溯。</p>
        <p>&nbsp;</p>
        <p><strong><span style="color: #4F81BD;">4．电子病历应用水平评价</span></strong></p>
        <p>电子病历作为智慧医院的核心和基础，处于医院信息化建设的优先领域。从2018年开始，国家卫健委就对医院电子病历水平有了具体明确的建设要求，各级医院需按照《电子病历系统功能应用水平分级评价方法及标准（试行）》开展电子病历信息化建设评价，持续提高本区域电子病历信息化整体水平。到2019年，所有三级医院要达到分级评价3级以上；到2020年，所有三级医院要达到分级评价4级以上，二级医院要达到分级评价3级以上。</p>
        <p>&nbsp;</p>
        <p>其中3级要求有跨部门统一的医疗数据字典并实现医嘱、检查、检验、住院药品、门诊药品、护理至少两类医疗信息跨部门的数据共享；4级要求实现药品配伍、相互作用自动审核，合理用药监测等功能并实现病人就医流程信息（包括用药、检查、检验、护理、治疗、手术等处理）的信息在全院范围内安全共享。</p>
        <p>&nbsp;</p>
        <p><span style="color: #4F81BD;"><strong>5．互联互通标准化成熟度测评</strong></span></p>
        <p>按照2020年互联互通标准化成熟度测评的要求，有几个关于信息化建设的重要内容，首先是医院的信息平台需要运行一年并通过初步验收，其次是核心信息系统包括集成平台需要通过三级等保，最后是互联互通四甲及以上需要具有互联网诊疗，这也意味着需要有互联网医院牌照。</p>
        <p>&nbsp;</p>
        <p><span style="color: #4F81BD;"><strong>6．智慧服务分级评估标准体系</strong></span></p>
        <p>2019年3月国家卫健委发布《医院智慧服务分级评估标准体系（试行）》，要求二级及以上医院参与智慧服务分级评价，相信不远的将来医院智慧服务会是各医疗机构的标配。</p>
        <p>其中关于信息化方面的重要内容有：</p>
        <p>·医院建立服务患者的信息系统，应用信息化手段对医疗服务流程进行部分优化，在挂号、智慧导医分诊、分时段预约、收费、检查、检验、入出院、药事服务等环节中，为患者提供信息化服务。</p>
        <p>·电子病历的部分信息通过互联网在医院内外进行实时共享，患者医疗信息在一定区域内实现互联互通。</p>
        <p>&nbsp;</p>
        <p><span style="color: #4F81BD;"><strong>7．医院智慧管理分级评估标准体系</strong></span></p>
        <p>2021年，国家卫健委发布《医院智慧管理分级评估标准体系（试行）》，目前该标准仅供各地、各医院推进智慧医院建设时参照使用，国家卫健委暂不开展相关评估工作。</p>
        <p>其中关于信息化方面的重要内容有：</p>
        <p>·建立具备数据共享功能的医院管理信息系统，数据可以通过网络在部门内部各岗位之间共享并进行处理。</p>
        <p>·依托医院管理信息系统实现业务联动，通过数据接口方式实现医院管理、医疗、护理、患者服务等主要管理系统（如会计、收费、医嘱等系统）数据交换。</p>
        <p>·各管理部门能够利用院内的医疗、护理、患者服务、运营管理等系统，完成业务处理、数据核对、流程管理等医院精细化管理工作。</p>
        <p>&nbsp;</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
</style>